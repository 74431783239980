<template>
  <v-card v-if="registration" outlined>
    <v-toolbar flat>
      <v-toolbar-title class="mr-6">Inscription # {{ registration.id }}</v-toolbar-title>

      <v-chip
        outlined
        class="pa-0"
      >
        <span class="pl-3 pr-1">{{ registration.registration_date | formatDate }}</span>

        <v-divider vertical></v-divider>

        <div class="pr-3 pl-1 time-cell d-flex align-center">
          {{ registration.registration_time }}
        </div>
      </v-chip>

      <v-spacer></v-spacer>

      <template v-if="!registration.gestac && isAllowedToExport">
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          @click="exportToGestac"
          :loading="exportIsLoading"
        >
          Envoyer vers Gestac
        </v-btn>
      </template>
    </v-toolbar>

    <div class="pa-4">
      <div class="d-flex align-center">
        <span class="text-overline">Ordre # {{ registration.order_number }}</span>

        <v-spacer></v-spacer>

        <div
          v-for="location, index in locationsArray"
          :key="index"
          class="d-flex align-center"
        >
          <v-divider
            v-if="index !== 0"
            class="ml-2"
            vertical
          ></v-divider>

          <v-avatar
            :color="`primary lighten-${index + 1}`"
            class="ml-2 white--text"
            size="24"
            v-text="index + 1"
          ></v-avatar>

          <span class="ml-1 text-overline">{{ location }}</span>
        </div>
      </div>
    </div>

    <v-card-text>
      <v-expansion-panels
        v-model="activePanels"
        focusable
        multiple
        flat
      >
        <v-expansion-panel>
          <v-expansion-panel-header>Informations personnelles</v-expansion-panel-header>
          <v-expansion-panel-content class="pt-5">
            <v-list>
              <v-row>
              <v-col>

              <v-subheader>Identité</v-subheader>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon :color="getGenderColor(registration.gender)">
                    mdi-account
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.first_name }} {{ registration.last_name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="brown">mdi-cake</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.birth_date | formatDate }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="blue-grey">mdi-flag</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.nationality }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="teal lighten-2">mdi-id-card</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.id_number }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              </v-col>
              <v-col>

              <v-subheader>Contact</v-subheader>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="orange">mdi-email</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.email }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="light-green">mdi-phone</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.mobile }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              </v-col>
              <v-col>
              <v-subheader>Adresse</v-subheader>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="red">mdi-map-marker</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.street }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="grey">mdi-city</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.postal_code }} - {{ registration.city }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="blue">mdi-earth</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ registration.country }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>
              </v-col>
              </v-row>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Parcours scolaire</v-expansion-panel-header>
          <v-expansion-panel-content class="pt-5">
            <p class="text-subtitle-1 d-flex justify-space-between align-center">
              A étudié en Belgique :

              <v-chip>
                {{ registration.studied_in_belgium ? 'Oui' : 'Non' }}
              </v-chip>
            </p>

            <p class="text-subtitle-1 d-flex justify-space-between align-center">
              Est dans l'année d'obtention du {{ degreeTitle }} :

              <v-chip>
                {{ registration.graduate_this_year ? 'Oui' : 'Non' }}
              </v-chip>
            </p>

            <template v-if="!registration.graduate_this_year">
              <p class="text-subtitle-1 d-flex justify-space-between align-center">
                Date d'obtention du {{ degreeTitle }} :

                <v-chip>
                  {{ registration.graduation_date | formatDate }}
                </v-chip>
              </p>

              <p class="text-subtitle-1 d-flex justify-space-between align-center">
                Nom de l'établissement :

                <v-chip>
                  {{ registration.est_name }}
                </v-chip>
              </p>

              <p class="text-subtitle-1 d-flex justify-space-between align-center">
                Pays de l'établissement :

                <v-chip>
                  {{ registration.est_country }}
                </v-chip>
              </p>

              <p
                class="text-subtitle-1 d-flex justify-space-between align-center"
                v-if="!registration.studied_in_belgium"
              >
                A introduit une demande d'équivalence :

                <v-chip>
                  {{ getEquivalenceByStatus(registration.has_equivalence) }}
                </v-chip>
              </p>

              <p class="text-subtitle-1">Activités post {{ degreeTitle }} :</p>

              <v-expansion-panels inset>
                <v-expansion-panel
                  v-for="(activity, index) in registration.activities"
                  :key="index"
                  class="activity-panel"
                >
                  <v-expansion-panel-header>
                    <span class="activity-header">{{ activity.year }}</span>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <span>{{ activity.text }}</span>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Autres inscriptions</v-expansion-panel-header>
          <v-expansion-panel-content class="pt-5">
            <p class="text-subtitle-1">
              A sollicité une inscription dans d'autres cursus de la HEPH Condorcet :

              <ul class="font-italic">
                <li v-if="!registration.other_cursus">Non</li>

                <template v-else>
                  <li v-for="(reg, index) in registration.other_cursus.split(',')" :key="index">
                    {{ reg }}
                  </li>
                </template>
              </ul>
            </p>

            <p class="text-subtitle-1">
              A sollicité une inscription dans d'autres Haute Ecole :

              <ul class="font-italic">
                <li v-if="!registration.other_schools">Non</li>

                <template v-else>
                  <li v-for="(reg, index) in registration.other_schools.split(',')" :key="index">
                    {{ reg }}
                  </li>
                </template>
              </ul>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>

  <v-progress-linear
    v-else
    color="primary"
    indeterminate
  ></v-progress-linear>
</template>

<script>
import { getGenderColor } from '@/mixins/methodsMixin';

export default {
  name: 'AdminParaRegistrationDetails',

  props: {
    cursusId: String,
    instanceId: String,
    registrationId: String,
    cursusDept: String,
  },

  mixins: [getGenderColor],

  data: () => ({
    registration: null,
    registrationIsLoading: false,
    activePanels: [0, 1, 2],
    exportIsLoading: false,
  }),

  computed: {
    degreeTitle() {
      if (!this.registration) return '';
      return this.registration.studied_in_belgium ? 'CESS' : 'bac';
    },

    locationsArray() {
      if (!this.registration) return [];
      return this.registration.locations.split(', ');
    },

    isAllowedToExport() {
      const isGes = this.$store.state.user.roles.includes(`APP.INS.PARA.${this.cursusDept}`);
      const isAdmin = this.$store.getters.hasAdminRole;
      return isGes || isAdmin;
    },
  },

  async created() {
    await this.getRegistrationDetails();
  },

  methods: {
    async getRegistrationDetails() {
      await this.$store.dispatch('getAccessToken');
      try {
        this.registrationIsLoading = true;
        const response = await this.$http.get(`admin/registration-get-by-id.php?registration_id=${this.registrationId}&type=para`);
        this.registration = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.registrationIsLoading = false;
      }
    },

    getEquivalenceByStatus(status) {
      switch (status) {
        case 'pending': return 'Oui - en attente';
        case 'yes': return 'Oui - reçue';
        case 'no': return 'Non';
        default: return 'Erreur';
      }
    },

    async exportToGestac() {
      await this.$store.dispatch('getAccessToken');
      try {
        this.exportIsLoading = true;
        await this.$http.get(`admin/export-gestac.php?registration_id=${this.registrationId}`);
        this.registration.gestac = true;
        this.$store.commit('SET_NOTIFICATION', {
          isVisible: true,
          text: 'Export vers gestac réussi',
          color: 'success',
        });
      } catch (error) {
        this.$store.commit('SET_NOTIFICATION', {
          isVisible: true,
          text: error.response.data,
          color: 'error',
        });
      } finally {
        this.exportIsLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.activity-panel .v-expansion-panel-header--active {
 color: rgba(0, 0, 0, 0) !important;
}

.activity-header {
  color: rgba(0, 0, 0, 0.87) !important;
}

.time-cell {
  height: 100%;
  background-color: #e4e4e4;
}
</style>
